import React from 'react'

function TwitterIcon(props) {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.8714 1.74769C18.2068 2.04308 17.4856 2.24862 16.7299 2.33846C17.5095 1.87479 18.0933 1.14268 18.3717 0.279385C17.6493 0.705231 16.8529 1.01785 16 1.18154C15.6507 0.809172 15.2288 0.512249 14.7604 0.309051C14.292 0.105852 13.7869 0.000676455 13.2763 0C12.7147 0.000394677 12.1604 0.127689 11.6548 0.372374C11.1493 0.617058 10.7056 0.972809 10.3568 1.41305C10.008 1.8533 9.76325 2.36665 9.64073 2.91478C9.51821 3.4629 9.52112 4.03162 9.64925 4.57846C8.16597 4.50444 6.71485 4.11914 5.39022 3.4476C4.06559 2.77606 2.89708 1.83331 1.96064 0.680615C1.48005 1.4991 1.33126 2.47059 1.54483 3.39539C1.75841 4.3202 2.31813 5.12806 3.10894 5.65292C2.51613 5.63599 1.93626 5.4755 1.4191 5.18523V5.23446C1.41925 6.09563 1.71783 6.93014 2.26402 7.59594C2.81022 8.26173 3.57026 8.71768 4.41479 8.88615C4.10217 8.98462 3.77479 9.02523 3.43756 9.02523C3.20002 9.02523 2.96248 9.00062 2.74094 8.96C2.97954 9.70162 3.44383 10.3502 4.06895 10.8151C4.69407 11.28 5.4488 11.5381 6.22771 11.5532C4.9085 12.5909 3.27846 13.1545 1.60002 13.1532C1.29602 13.1532 1.00064 13.136 0.713867 13.104C2.41983 14.2 4.40554 14.7812 6.43325 14.7778C13.2923 14.7778 17.0425 9.09908 17.0425 4.16862C17.0425 4.00369 17.0425 3.84862 17.0339 3.68369C17.7493 3.1445 18.3703 2.49028 18.8714 1.74769Z" fill="black" />
    </svg>

  )
}

export default TwitterIcon
