import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useLocation } from '@reach/router'

const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  /* font-family: Suisse Intl; */
  /* color: ${props => props.theme.core.content.foreground}; */
  color: #777777;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  display: flex;
  align-items: center;

  :hover,
  :focus {
    opacity: 0.8;
  }
  font-family: Suisse Intl;
`

const StyledLinkPill = styled(StyledLink)`
  padding: 10px 12.8px;
  background: #eeeeee;
  border-radius: 100px;
  color: ${props => props.theme.core.content.primary};
  font-family: Suisse Intl;
`

const NavLink = ({
  children,
  activeClassName,
  activeStyle,
  onClick,
  partiallyActive,
  replace,
  state,
  to,
  type,
  style = {},
}) => {
  let LinkComponent = StyledLink
  const { pathname } = useLocation()

  if (type === 'pill') {
    LinkComponent = StyledLinkPill
  }

  return (
    <LinkComponent
      activeClassName={activeClassName}
      activeStyle={activeStyle}
      onClick={onClick}
      partiallyActive={partiallyActive}
      replace={replace}
      state={state}
      to={to}
      type={type}
      style={{ ...style, color: pathname === to ? '#000000' : style.color }}
    >
      {children}
    </LinkComponent>
  )
}

export default NavLink