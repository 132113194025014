import { navigate } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'
import Loader from './loader'

// const StyledButton = styled.button`
//   width: 12.5rem;
//   height: 3.5rem;
//   box-sizing: border-box;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   font-style: italic;
//   font-weight: bold;
//   font-size: 1.125rem;
//   line-height: 1.5rem;
//   cursor: pointer;
//   border: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   ${props =>
//     props.colorType === 'primary' &&
//     css`
//       background-color: ${props.theme.core.background.inversePrimary};
//       color: ${props.theme.core.content.inversePrimary};
//     `}

//   :hover,
//   :focus {
//     opacity: 0.8;
//   }

//   :disabled {
//     opacity: 0.3;
//     pointer-events: none;
//   }
// `
const StyledButton = styled.button`
  padding: 10px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  ${props =>
    props.color === 'secondary' ?
      css`
      background-color: ${props.theme.core.background.primary};
      color: ${props.theme.core.content.primary};
    ` : css`
      background-color: ${props.theme.core.background.inversePrimary};
      color: ${props.theme.core.content.inversePrimary};
    `
  }
  ${props => props.secondary && css`
    background-color: transparent;
    border: 1px solid #000000;
    color: ${props.theme.core.content.primary};
  `}
  :hover,
  :focus {
    opacity: 0.8;
  }

  :disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2000px;
  transition: 200ms;
  height: 40px;
  font-family: Suisse Intl;
`


const Button = ({ children, secondary = false, colorType = 'primary', to = '', href = '', onClick, loading, ...props }) => (
  <StyledButton
    {...props}
    color={colorType}
    secondary={secondary}
    disabled={props.disabled || loading}
    onClick={onClick ? onClick : (to ? () => navigate(to) : href ? () => window.open(href) : undefined)}
  // className={'dark:bg-white dark:text-black' + ` ${props.className}`}
  >
    {children} {loading && <Loader style={{ marginLeft: '12px' }} />}
  </StyledButton>
)

export default Button
