import React from 'react'

function YoutubeIcon(props) {
  return (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21.138 7.99934C21.138 7.99934 21.138 4.57667 20.7287 3.03934C20.6177 2.62404 20.4003 2.24487 20.0979 1.93926C19.7956 1.63365 19.4188 1.41217 19.0047 1.29667C17.4754 0.888672 11.3594 0.888672 11.3594 0.888672C11.3594 0.888672 5.2447 0.888672 3.71536 1.29667C3.29987 1.40935 2.92155 1.62989 2.61879 1.93594C2.31602 2.24199 2.09956 2.62265 1.99136 3.03934C1.58203 4.57667 1.58203 7.99934 1.58203 7.99934C1.58203 7.99934 1.61803 11.422 2.02603 12.9593C2.13751 13.3759 2.35621 13.756 2.66033 14.0618C2.96445 14.3676 3.34338 14.5883 3.75936 14.702C5.27936 15.11 11.3954 15.11 11.3954 15.11C11.3954 15.11 17.5114 15.11 19.0394 14.702C19.4552 14.5897 19.8338 14.3692 20.1369 14.0632C20.4399 13.7571 20.6565 13.3762 20.7647 12.9593C21.1727 11.422 21.138 7.99934 21.138 7.99934ZM9.58203 10.906V5.09267L14.4714 7.99934L9.58203 10.906Z" fill="black" />
    </svg>

  )
}

export default YoutubeIcon
