import React from 'react'
import { Link, navigate } from 'gatsby'
import styled, { useTheme } from 'styled-components'
import Box from './box'
import Typography from './typography'
import Li from './li'

import Linkedin from '../icons/linkedin'
import Twitter from '../icons/twitter'
import Youtube from '../icons/youtube'
import Button from './button'
import PulseLogo from '../icons/pulseLogoWhite'
import { isFirefox } from 'react-device-detect'
import extensionLinks from '../utils/constants/extensionLinks'

const FooterContainer = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding: 32px 0;
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  background-color: #000;

  #logo {
    * {
      fill: #ffffff;
    }
  }

  .social-icon {
    * {
      fill: #ffffff;
    }
  }

  #slogan {
    color: ${props => props.theme.core.content.stateDisabled};
  }

  .text-primary {
    color: ${props => props.theme.core.content.inversePrimary};
  }

  .text-secondary {
    color: ${props => props.theme.core.content.inverseSecondary};
  }

  // @media ${props => props.theme.device.mobileL} {
  //   padding: 30px;
  //   padding-left: ${props => props.theme.spacing(2)};
  //   padding-right: ${props => props.theme.spacing(2)};
  // }

  // @media ${props => props.theme.device.tablet} {
  //   padding: 60px 0;
  //   padding-left: ${props => props.theme.spacing(2)};
  //   padding-right: ${props => props.theme.spacing(2)};
  //   background-color: #ffffff;

  //   .text-primary {
  //     color: ${props => props.theme.core.content.primary};
  //   }

  //   .text-secondary {
  //     color: ${props => props.theme.core.content.primary};
  //   }

  //   #logo {
  //     * {
  //       fill: ${props => props.theme.core.content.primary};
  //     }
  //   }

  //   #slogan {
  //     color: ${props => props.theme.core.content.secondary};
  //   }

  //   .social-icon {
  //     * {
  //       fill: ${props => props.theme.core.content.primary};
  //     }
  //   }
  // }

  padding-left: ${props => props.theme.spacing(10)};
  padding-right: ${props => props.theme.spacing(10)};
  background-color: #ffffff;
  .social-icon {
    * {
      fill: ${props => props.theme.core.content.primary};
    }
  }
  @media ${props => props.theme.device.tabletLToLower} {
    padding-left: 0px;
    padding-right: 0px;
    .social-icon {
      * {
        fill: #777777;
      }
    }
    padding-top: 48px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  height: 100%;
`

const LinksContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
  }
`

const AboutUs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  @media ${props => props.theme.device.tablet} {
    max-width: 250px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 24px;
  display: block;
  color: ${props => props.theme.colors.black};
`

const A = styled.a`
  border-right: 40px solid transparent;
  border-bottom: 10px solid transparent;
  color: ${props => props.theme.colors.black};
  display: flex;
  align-items: center;
`

const FooterComponent = () => {
  const theme = useTheme()
  return (
    <>
      <div className='bg-black px-[108px] py-48 tabletLToLower:py-32 w-full flex justify-between tabletLToLower:flex-col tabletLToLower:px-0 tabletLToLower:text-center' style={{
        transform: 'translateZ(10px)'
      }}>
        <div>
          <div className='text-4xl leading-[42px] text-white font-display tabletLToLower:leading-30 tabletLToLower:text-2xl'>
            Try Pulse for free.
          </div>
          <div className='mt-8 text-lg leading-22 text-gray-foreground-400 tabletLToLower:text-base tabletLToLower:leading-20'>
            The heartbeat of high-functioning organizations.
          </div>
        </div>
        <div className='grid place-content-center tabletLToLower:mt-24'>
          <Button
            colorType='secondary'
            onClick={() => navigate('/signup/')}
          >
            <span className='tabletLToLower:hidden block'>
              Try pulse for free
            </span>
            <span className='tabletLToLower:block hidden'>
              Create an account
            </span>
          </Button>
        </div>
      </div>
      <FooterContainer>
        <div className='flex h-[347px] justify-between tabletLToLower:h-auto tabletLToLower:w-full tabletLToLower:pb-48 tabletLToLower:flex-col'>
          <div className='flex flex-col justify-between tabletLToLower:px-16'>
            <PulseLogo
              fill={theme.colors.black}
            />
            <div className='flex tabletLToLower:hidden'>
              <a
                href="https://twitter.com/pulseasync"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter fill="#000000" className="social-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/getthepulse"
                target="_blank"
                rel="noopener noreferrer"
                className='ml-12'
              >
                <Linkedin fill="#000000" className="social-icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC-_YR6vFlYiDl28_nBRtlQA"
                target="_blank"
                rel="noopener noreferrer"
                className='ml-12'
              >
                <Youtube fill="#000000" className="social-icon" />
              </a>
            </div>
          </div>

          <div className='flex tabletLToLower:grid tabletLToLower:grid-cols-2 tabletLToLower:px-16 tabletLToLower:mt-48 tabletLToLower:gap-24'>
            <div>
              <div className='font-semibold text-sm leading-17 font-display'>
                Product
              </div>
              <div className='mt-8 text-gray-foreground text-sm leading-17'>
                <Li>
                  <Link to="/" className='text-gray-foreground'>
                    Features
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/integrations/" className='text-gray-foreground'>
                    Integrations
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <a
                    href={isFirefox ? extensionLinks.FIREFOX : extensionLinks.CHROME}
                    style={{ textDecoration: 'none' }}
                    className="text-gray-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Browser Extension
                  </a>
                </Li>
                <Li className='mt-4'>
                  <a
                    href="https://sametabdev.slack.com/apps/A01FKADQ35F-pulse"
                    style={{ textDecoration: 'none' }}
                    className="text-gray-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Slack
                  </a>
                </Li>
                {/* <Li className='mt-4'>
                  <Link to="/use-cases/marketing" className='text-gray-foreground'>
                    *Zoom
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/internal-comm" className='text-gray-foreground'>
                    *Google Meet
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/ops" className='text-gray-foreground'>
                    *Discord
                  </Link>
                </Li> */}
                <Li className='mt-4'>
                  <Link to="/pricing" className='text-gray-foreground'>
                    Pricing
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/signup/" className='text-gray-foreground'>
                    Sign up
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/login/" className='text-gray-foreground'>
                    Login
                  </Link>
                </Li>
              </div>
            </div>

            <div className='ml-24 tabletLToLower:ml-0'>
              <div className='font-semibold text-sm leading-17 font-display'>
                Use cases
              </div>
              <div className='mt-8 text-gray-foreground text-sm leading-17'>
                <Li>
                  <Link to="/use-cases/leadership" className='text-gray-foreground'>
                    Leadership
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/engineering" className='text-gray-foreground'>
                    Engineering
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/product" className='text-gray-foreground'>
                    Product
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/design" className='text-gray-foreground'>
                    Design
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/sales" className='text-gray-foreground'>
                    Sales
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/marketing" className='text-gray-foreground'>
                    Marketing
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/internal-comms" className='text-gray-foreground'>
                    Internal Comms
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/use-cases/hr" className='text-gray-foreground'>
                    People Ops
                  </Link>
                </Li>
                {/* <Li className='mt-4'>
                  <Link to="/use-cases/dao" className='text-gray-foreground'>
                    *DAOs
                  </Link>
                </Li> */}
              </div>
            </div>

            <div className='ml-24 tabletLToLower:ml-0'>
              <div className='font-semibold text-sm leading-17 font-display'>
                Company
              </div>
              <div className='mt-8 text-gray-foreground text-sm leading-17'>
                <Li>
                  <Link to="/privacy-policy/" className='text-gray-foreground'>
                    Privacy Policy
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/customers/" className='text-gray-foreground'>
                    Customers
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/terms/" className='text-gray-foreground'>
                    Terms
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <a
                    href="mailto:jobs@pulse.so"
                    style={{ textDecoration: 'none' }}
                    className="text-gray-foreground"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jobs
                  </a>
                </Li>
              </div>
            </div>

            <div className='ml-24 tabletLToLower:ml-0'>
              <div className='font-semibold text-sm leading-17 font-display'>
                Resources
              </div>
              <div className='mt-8 text-gray-foreground text-sm leading-17'>
                <Li>
                  <Link to="/blog/" className='text-gray-foreground'>
                    Blog
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/faqs/" className='text-gray-foreground'>
                    FAQs
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/operators/" className='text-gray-foreground'>
                    Operators
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/changelog/" className='text-gray-foreground'>
                    Changelog
                  </Link>
                </Li>
                <Li className='mt-4'>
                  <Link to="/privacy-policy/" className='text-gray-foreground'>
                    Privacy Policy
                  </Link>
                </Li>
              </div>
            </div>

            <div className='ml-24 tabletLToLower:ml-0'>
              <div className='font-semibold text-sm leading-17 font-display'>
                About
              </div>
              <div className='mt-8 text-gray-foreground text-sm leading-17 max-w-[252px]'>
                We believe the best companies operate like elite athlete teams. To perform, they need strong alignment and high discipline. If we can build a product to help teams stay on the same page more effectively, we can help them get more done and accomplish otherwise impossible missions.
              </div>
            </div>
          </div>

          <div className='hidden tabletLToLower:flex tabletLToLower:mt-48 tabletLToLower:px-16'>
            <a
              href="https://twitter.com/pulseasync"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter fill="#000000" className="social-icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/getthepulse"
              target="_blank"
              rel="noopener noreferrer"
              className='ml-12'
            >
              <Linkedin fill="#000000" className="social-icon" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC-_YR6vFlYiDl28_nBRtlQA"
              target="_blank"
              rel="noopener noreferrer"
              className='ml-12'
            >
              <Youtube fill="#000000" className="social-icon" />
            </a>
          </div>
          <div className='hidden tabletLToLower:flex tabletLToLower:mt-48 tabletLToLower:px-16 text-gray-foreground text-sm leading-17'>
            ©2022 Pulse
          </div>
        </div>
        {/* <InnerContainer>
          <Box>
            <PulseLogo
              fill={theme.colors.black}
            />
          </Box>
          <Box height="50px" />

          <LinksContainer display="flex" justifyContent="space-between">
            <Box>
              <Typography
                className="text-primary"
                colorType="colors.black"
                component="h5"
                textType="label-large"
                fontWeight={500}
                style={{ fontFamily: theme.typography.fontFamilyGTBold }}
              >
                Product
              </Typography>
              <Li>
                <StyledLink to="/integrations/" className="text-secondary">
                  Integrations
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/browser-extension/" className="text-secondary">
                  Browser Extension
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/pricing/" className="text-secondary">
                  Pricing
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/signup/" className="text-secondary">
                  Sign Up
                </StyledLink>
              </Li>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography
                colorType="colors.black"
                component="h5"
                textType="label-large"
                fontWeight={500}
                style={{ fontFamily: theme.typography.fontFamilyGTBold }}
                className="text-primary"
              >
                Resources
              </Typography>
              <Li>
                <StyledLink to="/blog/" className="text-secondary">
                  Blog
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/faqs/" className="text-secondary">
                  FAQs
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/operators/" className="text-secondary">
                  Operators
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/changelog/" className="text-secondary">
                  Changelog
                </StyledLink>
              </Li>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography
                colorType="colors.black"
                component="h5"
                textType="label-large"
                fontWeight={500}
                style={{ fontFamily: theme.typography.fontFamilyGTBold }}
                className="text-primary"
              >
                Company
              </Typography>
              <Li>
                <StyledLink to="/privacy-policy/" className="text-secondary">
                  Privacy Policy
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/terms/" className="text-secondary">
                  Terms
                </StyledLink>
              </Li>
              <Li>
                <A
                  href="mailto:support@pulse.so"
                  style={{ textDecoration: 'none' }}
                  className="text-secondary"
                >
                  Contact Us
                </A>
              </Li>
            </Box>

            <AboutUs>
              <Typography
                colorType="colors.black"
                component="h5"
                textType="label-large"
                fontWeight={500}
                style={{ fontFamily: theme.typography.fontFamilyGTBold }}
                className="text-primary"
              >
                About Us
              </Typography>
              <Typography
                colorType="colors.black"
                textType="label-small"
                className="text-secondary"
              >
                We believe the best companies operate like elite athlete teams. To
                perform, they need strong alignment and high discipline. If we can
                build a product to help teams stay on the same page more
                effectively, we can help them get more done and accomplish
                otherwise impossible missions.
              </Typography>
            </AboutUs>
          </LinksContainer>

          <Box height="40px" />

          <Box display="flex" alignItems="center">
            <Li>
              <A
                href="https://twitter.com/pulseasync"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter fill="#000000" className="social-icon" />
              </A>
            </Li>
            <Li>
              <A
                href="https://www.linkedin.com/company/getthepulse"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin fill="#000000" className="social-icon" />
              </A>
            </Li>
            <Li>
              <A
                href="https://www.youtube.com/channel/UC-_YR6vFlYiDl28_nBRtlQA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Youtube fill="#000000" className="social-icon" />
              </A>
            </Li>
          </Box>

          <Box height="60px"></Box>

          <Typography textType="paragraph-xSmall" id="slogan">
            © {new Date().getFullYear()} Pulse · Engineered with grit from all
            around the world
          </Typography>
        </InnerContainer> */}
      </FooterContainer>
    </>
  )
}
export default FooterComponent
