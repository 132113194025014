import React from 'react'
import styled, { useTheme } from 'styled-components'

// Ex: Passing colors.gray.500 as colorType
// will return the theme.colors.gray.500 value
function getColor(keys, colors) {
  const key = keys.shift()
  const color = colors[key]

  if (keys.length === 0) {
    return color
  }

  return getColor(keys, color)
}

const StyledElement = styled.p`
  color: ${props => props.color};
  ${props => props.theme.textType[props.textType]};
`

const Typography = ({
  component = 'p',
  className,
  id,
  textType,
  children,
  style,
  fontWeight,
  colorType = 'black',
  ...rest
}) => {
  const theme = useTheme()
  const keyValues = colorType.split('.')
  const color = getColor(keyValues, theme) || 'black'

  return (
    <StyledElement
      style={{
        ...style,
        ...rest,
        fontWeight,
      }}
      textType={textType}
      color={color}
      className={className}
      id={id}
      as={component}
    >
      {children}
    </StyledElement>
  )
}

export default Typography
