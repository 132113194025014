import React from 'react'

function LinkedinIcon(props) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_176_53214)">
        <path d="M14.9394 0.667969H2.43937C1.84637 0.667969 1.35938 1.14097 1.35938 1.72097V14.274C1.35938 14.861 1.84637 15.334 2.43937 15.334H14.9394C15.5394 15.334 16.0264 14.861 16.0264 14.274V1.72097C16.0264 1.14097 15.5394 0.667969 14.9394 0.667969ZM5.71238 13.168H3.53237V6.16797H5.71238V13.168ZM4.61937 5.20797C4.37045 5.20777 4.12718 5.13379 3.92029 4.99537C3.7134 4.85696 3.55219 4.66032 3.45702 4.43031C3.36185 4.20029 3.337 3.94723 3.38562 3.7031C3.43423 3.45898 3.55411 3.23474 3.73013 3.05872C3.90614 2.88271 4.13038 2.76282 4.37451 2.71421C4.61864 2.6656 4.8717 2.69045 5.10171 2.78561C5.33172 2.88078 5.52836 3.04199 5.66678 3.24888C5.8052 3.45577 5.87918 3.69905 5.87937 3.94797C5.88637 4.64097 5.31938 5.20797 4.61937 5.20797ZM13.8594 13.168H11.6864V9.76797C11.6864 8.95397 11.6724 7.91397 10.5524 7.91397C9.41937 7.91397 9.24638 8.80097 9.24638 9.71397V13.174H7.07238V6.17397H9.15938V7.12797H9.18638C9.47938 6.57397 10.1864 5.99397 11.2464 5.99397C13.4464 5.99397 13.8524 7.44097 13.8524 9.32797V13.168H13.8594Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_176_53214">
          <rect width="16" height="16" fill="white" transform="translate(0.692383)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default LinkedinIcon
